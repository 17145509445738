.hero {
	@include bp($point_4, min) {
		padding-top: 21.6rem;
	}
	@include bp($point_4 - 1) {
		padding: 9.2rem var(--gl-indent) 3.4rem;
	}
}

.hero__block {
	position: relative;

	width: 100%;

	@include bp($point_4, min) {
		padding-left: 12.8rem;
	}
}

.hero__block_head {
	@extend %global_flex_block_row_wrap_flex-end;
	position: relative;

	@include bp($point_4, min) {
		&:not(:last-child) {
			margin-bottom: 7.2rem;
		}

		padding-right: 6.8rem;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 6.2rem;
	}
}

.hero__title {
	width: 100%;
	max-width: 93rem;
	font-weight: 400;
	line-height: math(7.2, 4.8);

	color: $dark;

	@include bp($point_4, min) {
		font-size: 4.8rem;
		letter-spacing: .2rem;
	}
	@include bp($point_4 - 1) {
		font-size: 2.4rem;
		letter-spacing: 0.76px;
	}

}

.hero__img_block {
	position: relative;

	width: 100%;
	padding-top: calc( 756 / 1376 * 100%);

	&::before {
		z-index: -1;

		position: absolute;
		bottom: 50%;

		content: '';

		width: 100vw;
		height: 200vh;
		transform: translateX(-50%);

		background-color: $white;

		pointer-events: none;

		@include bp($point_4, min) {
			left: calc(50% - 6.5rem);
		}

		@include bp($point_4 - 1) {
			left: 50%;
		}
	}

	@include bp($point_4 - 1) {
		height: 50.4rem;
	}
}

.hero__img_block__in,
.hero__img_block__in_in {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.parallaxImg {
	overflow: hidden;
}

.hero__img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.hero__block_decor {
	position: absolute;

	overflow: hidden;

	@include bp($point_4, min) {
		top: 0;
		left: 4.3rem;
		bottom: -10.6rem;
		width: 1rem;
	}
	@include bp($point_4 - 1) {
		z-index: -1;
		top: 17rem;
		left: 23.4rem;
		bottom: -8.6rem;
		width: .6rem;
	}
}

.hero__block_decor_x {
	position: absolute;
	top: 12.8rem;
	left: -3rem;
	right: 12rem;
	z-index: 0;

	height: .6rem;

	@include bp($point_4, min) {
		display: none;
	}
}

.hero__block_decor__in {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background: rgba(161, 130, 2, 0.1);
}