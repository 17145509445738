.form_textarea__label {
  @extend %form_el_label;
	margin: 0 0 0.2rem 0;
}

.form_textarea__element {
  @extend %form_el_default;

  padding: 2rem;
  
  box-shadow: inset 0 0 0 2px $input_border_color;
  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 2px $input_border_color_active;
  }

  @include bp($point_4, min){
    height: 20rem;
  }
  @include bp($point_4 - 1){
    height: 21.4rem;
  }
}