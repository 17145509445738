//---------------------------------------------fonts
$font_path: "../fonts/";
$font_1: 'Poppins', sans-serif;
$font_size_base: 1.8rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;

$dark: #1e1e0a;
$dark_2: #0f0f05;
$sand: #988b4f;
$sand_2: #a18202;
$light: #f5f2e4;
$light_2: #f3f3f3;

$color_text_base: $dark;
$color_link_base:$dark;
$body_bg: $light_2;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popup : 101,
	header : 100,
	footer : 100
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$page_width: 150.4rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_radius:.5rem;
//---------------------------------------------global values###

//---------------------------------------------media points

$point_0: 1920px;
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
$point_9: 390px;
$point_container: 1560px ; // container width + spacing
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 3.6rem;
$input_offset: 0 0 1rem 0;
$input_font_size: 2.4rem;
$input_border_color: rgba($dark, .3);
$input_border_color_active: $black;
$input_placeholder_color: rgba($dark, .4);
$input_text_color: $dark;
$input_radius: 0;
//---------------------------------------------forms###