//------------------------------------------------------------layout
.header {
	position: absolute;
	top: 0;
	left: 0;

	height: var(--height-header);
	width: 100%;
	padding: 0 var(--gl-indent);

	font-size: 1rem;

	will-change: transform;

	@include z-index(header);


	@include bp($point_1 + 1, min) {
		overflow: hidden;
	}


	@include bp($point_1) {
		padding-top: 8.2rem;
	}


}

.header__in {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;

	width: 100%;
	max-width: ($page_width - 8rem);
	height: 100%;
	margin: 0 auto;


	// @include bp(1367px, min) {
	// 	padding-left: 4.2rem;
	// }

	@include bp($point_1 + 1, min) {
		@include flex_block(row, wrap, flex-start, flex-end, flex-end);
	}

}

.header__logo {
	@extend %transition_opacity;

	display: block;


	@include bp($point_1 + 1, min) {
		width: 18.2rem;
		&:hover, &:active, &:focus {
			opacity: .8;
		}
	}


	@include bp($point_1) {
		z-index: 210;

		position: relative;

		width: 12.5rem;
	}


}

.header__logo_in {
	display: block;
	width: 100%;
	height: auto;
}

.header__nav {

	@include bp(1800px, min) {
		margin-right: -17rem;
	}

	@include bp($point_1 + 1, min) {
		position: relative;

		margin-left: auto;
		padding-bottom: 3.8rem;

		&.header__nav--mobile_mod {
			display: none;
		}
	}


	@include bp($point_1) {
		&.header__nav--desktop_mod {
			display: none;
		}

		&.header__nav--mobile_mod {
			opacity: .98;
			z-index: 200;

			position: fixed;
			top: 0;
			left: 0;

			width: 100%;
			height: var(--full-screen);
			max-height: var(--full-screen);
			padding: 13rem var(--gl-indent);

			background-color: $sand;

			transform: translateY(-101%);

			transition: transform .2s ease;
			will-change: transform;

			overflow-x: hidden;
			overflow-y: auto;

			body.body--open_menu_state & {
				transform: translateY(0)
			}

			@include flex_block(row, wrap, center, center, center);
		}
	}

	@media (max-height: $point_6) {
		&.header__nav--mobile_mod {
			display: block;
			padding-top: 18rem;
		}
	}

}

.header_decor {
	position: absolute;
	left: -1.4rem;
	right: -30vw;
	bottom: 0;

	height: 1rem;

	overflow: hidden;


	@include bp($point_1) {
		display: none;
	}

}

.header_decor__in {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background: rgba(161, 130, 2, 0.1);
}

.nav_list {

	padding: 0;
	list-style: none;


	@include bp($point_1 + 1, min) {
		@include flex_block(row, wrap, flex-start, center, center);

		margin: 0 -5.4rem -1rem 0;
	}


	@include bp($point_1) {
		width: 100%;
	}


}

.nav_list__item {

	@include bp($point_1 + 1, min) {
		padding: 0 5.4rem 1rem 0;
	}


	@include bp($point_1) {
		width: 100%;
		max-width: 18rem;
		margin: 0 auto;
		padding-bottom: 2rem;

		border-bottom: 1px solid $light;

		text-align: center;

		&:not(:last-child) {
			margin-bottom: 2.4rem;
		}
	}
}

.nav_list__link {
	position: relative;
	text-decoration: none;
	letter-spacing: 0.06rem;
	line-height: .8;
	text-transform: uppercase;
	transition: color ease-in-out .3s;

	&:hover, &:active, &:focus {
		text-decoration: none;

		@include bp($point_1, min) {
			color: $sand_2;
		}

		@include bp($point_1 - 1) {
			color: darken($white, 25%);
		}
	}

	@include bp($point_1 + 1, min) {
		font-weight: 400;
		font-size: 1.4rem;
		color: #1E1E0A;
	}

	@include bp($point_1) {
		font-weight: 500;
		font-size: 1.6rem;
		color: $light;
	}

}
//------------------------------------------------------------layout###
//---------------------------------------------trigger
@mixin user_menu_butt_line {
	@extend %transition_background;

	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 2px;
	margin-top: -1px;
	background-color: $sand;

	body.body--open_menu_state &,
	body.body--scroll_state &  {
		background-color: $white;
	}
}

.menu_trigger {
	z-index: 210;

	position: fixed;
	top: 3.4rem;
	right: var(--gl-indent);

	height: 2.2rem;
	width: 2.2rem;

	cursor: pointer;

	transition: transform ease-in-out .3s;

	body.body--scroll_state & {
		transform: translate(0, 5rem);
	}

	&:before {
		@extend %transition_opacity;

		z-index: 1;

		position: absolute;
		top: 50%;
		left: 50%;

		content: '';

		width: 6rem;
		height: 6rem;
		margin: math(-6rem, 2) 0 0 math(-6rem, 2);

		border-radius: 50%;

		background-color: $sand;

		opacity: 0;

		pointer-events: none;

		body.body--scroll_state & {
			opacity: 1;

			// transform: translate(0, 2rem);
		}

	}

	@include bp($point_1 + 1, min) {
		display: none;
	}

}

.menu_trigger__in {
	z-index: 2;

	position: absolute;

	width: 100%;
	height: 100%;

	&:before {
		content: '';
		transform: translate(0, -.7rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&:after {
		content: '';
		transform: translate(0, .7rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	body.body--open_menu_state & {
		&:before {
			transform: (rotate(45deg) translate(0, 0));
		}

		&:after {
			transform: (rotate(-45deg) translate(0, 0));
		}
	}
}

.menu_trigger__decor {
	transition: all .3s ease;

	body.body--open_menu_state & {
		opacity: 0;
		transform: translate(100%, 0);
	}

	@include user_menu_butt_line;
}

.nav_logo {
	position: absolute;
	top: 3.4rem;
	left: var(--gl-indent);

	display: block;
	width: 12.4rem;
	height: 7.4rem;


	@include bp($point_1 + 1, min) {
		display: none;
	}

}

.nav_logo__in {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: contain;
}

//---------------------------------------------trigger###