@import "base_ui/forms/form_input";
@import "base_ui/forms/form_textarea";
@import "base_ui/forms/form_select";
@import "base_ui/forms/form_checkbox";
@import "base_ui/forms/form_radio";
@import "base_ui/forms/success_form";

.form_col {
	margin: 0 0 3.4rem 0;

	&:last-child {
		margin-bottom: 0;
	}
}