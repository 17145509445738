.card_center {
	padding-left: var(--gl-indent);
	padding-right: var(--gl-indent);

	@include bp($point_4, min) {
		padding-top: 13.5rem;
		padding-bottom: 19rem;
	}

	@include bp($point_4 - 1) {
		padding-top: 3.5rem;
		padding-bottom: 20rem;
	}
}

.card_center__in {
	position: relative;
}

//========================== Decor

.card_center__decor {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	width: 100%;
	height: 100%;

}

.card_center__y_1,
.card_center__y_2 {
	position: absolute;

	background-color: rgba($sand_2, 0.1);

	@include bp($point_4, min) {
		width: 1rem;
	}

	@include bp($point_4 - 1) {
		width: .6rem;
	}
}

.card_center__y_1 {
	@include bp($point_4, min) {
		display: none;
	}

	@include bp($point_4 - 1) {
		height: 22.6rem;
		bottom: -34rem;

		right: 10%;
	}
}

.card_center__y_2 {
	@include bp($point_4, min) {
		bottom: -25.2rem;
		right: 11.4%;

		height: calc(100% + 38.5rem);
	}

	@include bp($point_4 - 1) {
		top: -6%;
		left: 9%;

		height: 50%;
		width: 0.6rem;
	}
}

.card_center__x {
	position: absolute;

	background-color: $sand_2;
	opacity: 0.1;

	@include bp($point_4, min) {
		right: -51%;
		top: 23%;

		height: 1rem;
		width: 40vw;
	}

	@include bp($point_4 - 1) {
		left: -53%;
		bottom: -10.4%;

		height: .6rem;
		width: 100%;
	}
}

.card_center__card {
	@include bp($point_1, min) {
		padding: 0 26rem;
	}

	@include bp($point_1 - 1) {
		padding: 0;
	}
}

.card_center__img {
	position: relative;
	display: block;

	height: 100%;
	width: 100%;

	@include bp($point_4, min) {
		max-width: 99.2rem;
		max-height: 49.2rem;

		margin-bottom: 3.6rem;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 3.2rem;
		height: max(46.2rem, 80vw);
	}
}

.card_center__img_in {
	@extend %cover_img;

	transform: scale(1.9);
	transform-origin: 0% 15%;

	@include bp ($point_4 - 1) {
		width: 130%;
		height: 105%;
		transform-origin: 0% 2%;
	}
}

.card_center__content {
	@include bp($point_1, min) {
		@include flex_block(row, wrap, flex-start, center, center);
		padding: 0 5.5rem 0 2.5rem;
	}

	@include bp ($point_4 - 1) {
		padding-left: 1rem;
	}
}

.card_center__content_text {
	@include bp($point_1, min) {
		width: 68%;
	}
}

.card_center__content_link {
	margin-left: auto;
}

.card_center__title {
	@extend %h3;

	@include bp($point_4, min) {
		margin-bottom: 3.4rem;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 1rem;
	}
}

.card_center__descr {
	@extend %descr;
	max-width: 98rem;

	@include bp($point_4, min) {
		margin-bottom: 2rem;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 1rem;
	}
}

.card_center__link {
	@extend %accent_btn;
	@extend %btn_size_1;

	height: 5.3rem;
	min-width: 17.4rem;
}