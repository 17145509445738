@mixin st_btn() {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  white-space: nowrap;

  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }

}

//color extends
%blue_btn_1 {
  font-weight: 700;
  color: $white;
  background-color: $color_link_base;
  transition: background-color .3s ease;

  @include st_btn();

  &:hover {
    background-color: darken($color_link_base, 10%);
  }

}

%accent_btn {
  color: $dark;
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom-color: $sand;
  transition: background-color .3s ease, color .3s ease;

  @include st_btn();

  &:hover {
    background-color: $sand;
    color: #fff;
  }
}

//size extends
%btn_size_1 {
  padding: 0 1.2rem;
  text-transform: uppercase;

  @include bp($point_4, min) {
    height: 5.8rem;
    min-width: 18rem;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 5.8rem;
		letter-spacing: 0.52px;
  }

  @include bp($point_4 - 1) {
    height: 4.3rem;
    min-width: 15.4rem;
    font-size: 1.2rem;
    line-height: 4.3rem;
    font-weight: 600;
		letter-spacing: 0.52px;
  }
}