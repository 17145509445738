%form_el_checkbox {
  display: block;
  position: relative;

  padding-left: 4.2rem;

  font-size: 1.3rem;
  letter-spacing: 0.54px;
  color: $dark;
  cursor: pointer;

  a{
    text-decoration: underline;
  }

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-1.34rem);
    left: 0;
    width: 2.7rem;
    height: 2.7rem;
    cursor: pointer;
  }

  &:before {
    content: '';
    border-radius: 2px;
    border: 2px solid rgba($color: $dark, $alpha: 0.3);

    .form_checkbox__element:checked+& {
      border: 2px solid rgba($color: $dark, $alpha: 1);
    }
  }

  &:after {
    @extend %icon-check;
    @extend %transition_opacity;

    text-align: center;
    line-height: 2.7rem;
    opacity: 0;
    color: $black;

    .form_checkbox__element:checked+& {
      opacity: 1;
    }
  }
}