.form_success {
	background: rgba(152, 139, 79, 0.5);
	color: $dark;
	position: fixed;
	z-index: 100;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	height: 50vh;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 5px;

	display: none;

	animation: fade 0.3s linear;

	@keyframes fade {

		0% {
			opacity: 0
		}

		100% {
			opacity: 1
		}
	}

	.form_success_img {
		width: 8%;
	}

	.close_popup {
		position: absolute;
		top: -15px;
		right: -15px;
		padding: 7px 10px;
		background-color: white;
		border: 1px solid $dark;
		border-radius: 50px;
		cursor: pointer;

		.close_popup_img {
			width: 1rem;
		}
	}

	.form_success_title {
		margin-top: 50px;
		font-size: 30px;
		text-align: center;
		font-weight: 400 !important;
	}
}

.form_success_display {
	display: flex;
}