.apartments_section {
	@include bp($point_4, min) {
		padding: 11rem var(--gl-indent) 0;
	}

	@include bp($point_4 - 1) {
		padding: 12.4rem var(--gl-indent) 0;
	}
}

//=================== DECOR

.apartments__decor {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	pointer-events: none;
}

.apartments__line_y {
	position: absolute;

	background: rgba($sand_2, .1);

	@include bp($point_4, min) {
		top: -11rem;
		left: 60%;
		height: 124%;
		width: 1rem;
	}

	@include bp($point_4 - 1) {
		top: 0;
		left: 82.2%;
		height: 10%;
		width: .6rem;
	}
}

// ================================

.apartments_info {
	// @extend %global_flex_block_row_wrap_flex-start_stretch;
	width: 100%;

	@include bp($point_1, min) {
		@include flex_block(row-reverse, wrap, flex-start, stretch);
		margin-left: -4.8rem;
	}
}

.apartments_info__col {
	position: relative;

	&:first-child {
		@include bp($point_1, min) {
			width: 36.7%;
			padding: 11rem 0 0 4rem;
		}

		@include bp($point_1 - 1) {
			padding-bottom: 8.6rem;
			z-index: 2;
		}
	}

	&:last-child {
		@include bp($point_1, min) {
			width: 60.7%;
			padding-right: 5.4rem;
		}

		@include bp($point_1 - 1) {
			width: 100vw;
			margin-left: -3rem;
			z-index: 2;
		}
	}
}

.apartments_images {
	margin-left: -3rem;
}

.apartments_images__slider {
	width: 100%;
}

.apartments_images__slider_item {
	width: 100%;
}

.apartments_img {
	position: relative;
	display: block;
	width: 100%;

	@include bp($point_4, min) {
		padding-top: calc(648 / 1104 * 100%);
	}

	@include bp($point_4 - 1) {
		padding-top: calc(336 / 420 * 100%);
	}
}

.apartments_img__in {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	object-fit: cover;

	transform: scale(2);
	transform-origin: 50% 80%;
}

.apartments_info__head {
	@include flex_block(row, wrap, flex-start);
	width: 100%;

	position: absolute;

	@include bp($point_1, min) {
		top: 0;
	}

	@include bp($point_1 - 1) {
		top: -7.5rem;
	}
}

.apartments_info_slider {
	height: 100%;
}

.apartments_info_slider__item {

	@include bp($point_1, min) {
		padding-bottom: 21rem;

		&.slick-active+.apartments_info_slider__item {
			margin-top: -10rem;
		}
	}
}

//=============== TEXT

.slider_count {
	@include flex_block(row, wrap, flex-start, center);

	margin-right: 1rem;

	font-weight: 200;
	letter-spacing: 0.61px;
	color: $dark;

	@include bp($point_4, min) {
		font-size: 1.4rem;
	}

	@include bp($point_4 - 1) {
		font-size: 1.2rem;
	}

	span {
		font-weight: 500;
		letter-spacing: 1.04;
		color: $dark;

		@include bp($point_4, min) {
			font-size: 2.4rem;
		}

		@include bp($point_4 - 1) {
			font-size: 1.6rem;
		}
	}
}

.slider_count_title {
	font-weight: 400;
	color: $dark;

	@include bp($point_2, min) {
		font-size: 2.4rem;
		letter-spacing: 1.04px;
	}

	@include bp($point_3) {
		font-size: 1.6rem;
		letter-spacing: 0.69px;
	}
}

.apartments_info__title {
	@extend %h2;

	@include bp($point_2, min) {
		margin-bottom: 5.4rem;
	}

	@include bp($point_3) {
		margin-bottom: 2rem;
	}
}

.apartments_info__descr {
	font-weight: 300;

	p {
		@include bp($point_2, min) {
			padding-bottom: 1.8rem;
		}

		@include bp($point_3) {
			padding-bottom: 1.6rem;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}

	p b {
		font-weight: 400;
		letter-spacing: normal;
	}

	@include bp($point_1, min) {
		max-width: 45rem;
		margin-bottom: 4.6rem;
	}

	@include bp($point_4, min) {
		font-size: 1.8rem;
		letter-spacing: 0.78px;
		line-height: 1.72;
	}

	@include bp($point_4 - 1) {
		font-size: 1.5rem;
		letter-spacing: 0.65px;
		line-height: 1.6;
	}
}

//================== ARROWS

.apartments_slider__arrow_prev,
.apartments_slider__arrow_next {
	position: absolute;
	z-index: 100;

	background: url('../images/arrow.svg') no-repeat left center;

	cursor: pointer;
	transition: transform ease-in-out .2s;

	@include bp($point_1, min) {
		width: 5.6rem;
		height: 5rem;
		background-size: 25.4rem 5rem;
	}

	@include bp($point_1 - 1) {
		width: 5.2rem;
		height: 2.6rem;
		background-size: 17rem 2.6rem;
	}
}

.apartments_slider__arrow_next {
	@include bp($point_1, min) {
		right: 83%;

		bottom: 5.4rem;
		transform: rotate(-90deg);

		&:hover {
			transform: translateY(-3px) rotate(-90deg);
		}
	}

	@include bp($point_1 - 1) {
		bottom: -5.8rem;
		right: -2rem;
		transform: rotate(180deg);

		&:hover {
			transform: translateX(-3px) rotate(180deg);
		}
	}
}

.apartments_slider__arrow_prev {


	@include bp($point_1, min) {
		right: 83%;
		bottom: 10.6rem;
		transform: rotate(90deg);

		&:hover {
			transform: translateY(3px) rotate(90deg);
		}
	}

	@include bp($point_1 - 1) {
		bottom: -5.8rem;
		right: 3rem;

		&:hover {
			transform: translateX(3px);
		}
	}
}