.map {
  display: block;
  max-width: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(245, 242, 228, 0.4);
    pointer-events: none;
  }

  @include bp($point_container - 1, $point_4 + 1) {
    height: 50rem;
    margin: 0 10rem 10rem -3rem;
  }

  @include bp($point_container, min) {
    height: 75.6rem;
    margin: 0 16rem 16.6rem calc((100vw - 0.5rem - $page_width) / -2);
  }

  @include bp($point_4 + 1, min) {
    width: 55.7vw;
    order: 1;
  }

  @include bp($point_4) {
    height: 38.2rem;
    max-width: none;
    order: 2;
  }

  @extend %full_width_tablet;
}

.map__decor {
	@include bp($point_4 - 1) {
		position: absolute;
		z-index: 1;
		bottom: -8.2rem;
		left: 82%;

		height: 21.6%;
		width: .6rem;
		background: rgba($sand_2, .1);
	}
}

.map__wrapper {
  display: flex;

  @include bp($point_4) {
    flex-wrap: wrap;
  }
}

.map__inner {
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
}

.map_descr {
  position: relative;
  padding: 3.4rem 0 0 0;

  .text_title {
    margin-bottom: 1.3rem;

    @include bp($point_4) {
      font-size: 2.4rem;
    }
  }

  @include bp($point_4 + 1, min) {
    order: 2;
  }

  @include bp($point_4) {
    padding: 5.7rem 5rem 5rem 6.8rem;
    background-color: #fff;
  }

  @extend %full_width_tablet;
}

.map_descr__text {
  font-weight: 200;

  @include bp($point_4 + 1, min) {
    font-size: 3rem;
    line-height: 4.6rem;
    letter-spacing: 1.3px;
  }

  @include bp($point_4) {
    font-size: 2.4rem;
    letter-spacing: 1px;
  }
}

.map_line {
  display: none;
  position: absolute;
  top: -11rem;
  width: 1rem;
  height: 97rem;
  @include bp($point_container, min){
    left: -8.4rem;
  }
  @include bp($point_4, min) {
    display: block;
  }
  @include bp($point_container - 1, $point_4 + 1) {
    left: -5.5rem;
  }
}

.map_line__inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba($color: $sand_2, $alpha: 0.1);
}