%h1 {
	font-size: 4rem;
	text-transform: uppercase;
}

// offsets

%title_offset {
	margin-bottom: 2rem;
}

%h2 {
	color: $dark;
	font-weight: 200;

	@include bp($point_4, min) {
		font-size: 4.8rem;
		letter-spacing: 2.08px;
	}
	@include bp($point_4 - 1) {
		font-size: 2.4rem;
		letter-spacing: 1px;
	}
}

%h3 {
	color: $dark;

	@include bp($point_4, min) {
		font-size: 3rem;
		letter-spacing: 1.3px;
		font-weight: 400;
	}
	@include bp($point_4 - 1) {
		font-size: 2rem;
		letter-spacing: 0.87px;
		font-weight: 500;
	}
}

%descr {
	font-weight: 200;
	color: $dark;

	@include bp($point_4, min) {
		font-size: 2.4rem;
		letter-spacing: 1.04px;
		line-height: 1.5;
	}
	@include bp($point_4 - 1) {
		font-size: 1.7rem;
		letter-spacing: 0.74px;
		line-height: 1.4;
	}
}