.zona {
	@extend %global_flex_block_row_wrap_flex-start_center;
	
	@media (min-width: $point_1) and (max-width: $page-width) {
		min-height: 97.6rem;
	}
	@media (min-width: $point_4) and (max-width: $point_1 - 1) {
		min-height: 80rem;
		padding: 8rem var(--gl-indent);
	}
	@include bp($point_4 - 1){
		padding: 8.6rem var(--gl-indent) 15.5rem;
	}
}

.zona__decor {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.zona__line_y {
	position: absolute;
	bottom: 0;
	height: 5.6%;
	background: rgba($sand_2, .1);

	@include bp($point_4, min) {
		display: none;
	}
	@include bp($point_4 - 1) {
		left: 82%;
		width: .6rem;
	}
}

.zona_section__header {
	display: flex;

	@include bp($point_4, min) {
		justify-content: space-between;
		align-items: flex-end;

		margin-bottom: 7.1rem;
	}
	@include bp($point_4 - 1)  {
		flex-direction: column;

		margin-bottom: 6.4rem;
	}
}
.zona_section__text {
	@extend %descr;

	@include bp($point_4, min) {
		width: 19.4rem;
	}
	@include bp($point_4 - 1)  {
		font-size: 1.7rem;
		line-height: 2.4rem;
	}
}
.zona_section__title {
	@extend %h2;

	@include bp($point_4, min) {
		max-width: 99rem;
		margin-bottom: 9.8rem;

	}
	@include bp($point_4 - 1)  {
		margin-bottom: 7rem;
	}
}

.zona_section__title strong{
	font-weight: 400;
	letter-spacing: normal;
}

.zona_section__devider {
	position: relative;

	@include bp($point_4, min) {
		width: 107.2rem;
		height: 1rem;
		top: 0.6rem;
		margin-right: -14%;
	}
	@include bp($point_4 - 1)  {
		width: 70rem;
		height: 0.5rem;

		margin-bottom: 7.5rem;
		margin-left: 6rem;

		order: -1;
	}
	@include bp($page-width - 1, min) {
		margin-right: -20%;
	}
}

.zona_section__devider_img {
	position: absolute;

	width: 100%;
	height: 100%;

	background-color: rgba($sand_2, 0.1);
}
