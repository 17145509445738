.list_with_icons {
	@include bp($point_4, min) {
		@include flex_block(row, wrap, flex-start, stretch);

		margin: 0 -2.6rem -3rem 0;

		align-content: stretch;
		text-align: left;
	}

	@include bp($point_4 - 1) {
		width: 83%;

		margin: 0 auto -7rem;
	}
}

.list_with_icons__item {
	@media (min-width: $point_4) and (max-width: $point_1) {
		width: 50%;

		padding: 0 6rem 6rem;
	}
	@include bp($point_1, min) {
		width: 25%;

		padding: 0 2.6rem 3rem 0;
	}
	@include bp($point_4 - 1) {
		width: 100%;

		padding: 0 0 7rem 0;
	}
}
.list_with_icons__wrap_img {
	position: relative;

	@include bp($point_1 - 1, min) {
		width: 14.1rem;
		height: 12.8rem;

		margin-bottom: 1.2rem;
	}
	@include bp($point_1) {
		width: 10.6rem;
		height: 9.6rem;

		margin: 0 auto 2.5rem;
	}
}
.list_with_icons__img {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}
.list_with_icons__descr {
	letter-spacing: 0.1rem;
	color: $dark;

	@include bp($point_4 - 1, min) {
		font-size: 2.4rem;
		line-height: 3.6rem;
	}
	@media(min-width: $point_4) and (max-width: $point_1) {
		text-align: center;
	}
	@include bp($point_4) {
		font-size: 2rem;
		line-height: normal;
		text-align: center;
	}
}