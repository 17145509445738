.footer {
	flex: 0 0 auto;
	width: 100%;
	min-height: var(--height-footer);
	background-color: #fff;
	font-size: 1rem;
	will-change: transform;
}

.footer_main {
	position: relative;
	max-width: 100%;
	margin: 0 auto;

	@include bp($point_4 - 1) {
		padding: 8.5rem var(--gl-indent) 12.4rem;
	}

	@include bp($point_4, min) {
		padding: 11rem var(--gl-indent) 19.3rem;
	}

	@include bp($point_1, min) {
		width: 156.4rem;
		display: flex;
		align-items: baseline;
	}

	.text_title {
		@include bp($point_4, min) {
			margin: 0 8.2rem 5.8rem 0;
		}

		@include bp($point_4 - 1) {
			margin: 0 0 6.2rem 0;
		}
	}
}

.footer_container {
	max-width: 100%;
	margin: 0 auto;

	@include bp($point_1, min) {
		width: 155rem;
		display: flex;
		align-items: baseline;
	}

}

.footer_links {
	display: block;

	@include bp($point_4, min) {
		margin: 0 10rem 7.4rem 0;
	}

	@include bp($point_4 - 1) {

		margin: 0 0 7.7rem 0;
	}
}

.footer_links__item {
	display: block;
	margin: 0 0 3.5rem 0;

	&:last-child {
		margin-bottom: 0;
	}
}

.footer_links__link {
	display: inline-block;
	padding: 0 0 1.2rem 0;
	border-bottom: 2px solid rgba($color: $sand, $alpha: 0.5);
	transition: border-bottom-color .3s linear;
	font-weight: 300;
	letter-spacing: 0.87px;

	&:hover,
	&:focus {
		border-bottom-color: $dark;
		text-decoration: none;
	}

	@include bp($point_4 - 1) {
		height: 3.5rem;
		font-size: 2rem;
	}

	@include bp($point_4, min) {
		height: 4.5rem;
		font-size: 3rem;
	}
}

.footer_form {
	max-width: 100%;
	margin: -.4rem auto 0 0;

	.form_checkbox {
		margin-bottom: 4.6rem;
	}

	.form_checkbox__label {
		@include bp($point_4, min) {
			line-height: 1.7;
		}
	}

	@include bp($point_1) {
		width: 100%;
	}

	@include bp($point_1 + 1, min) {
		width: 48rem;
	}
}

.footer_bottom {
	background-color: $dark_2;

	@include bp($point_4, min) {
		.footer_container {
			width: 150rem;
		}
	}
}

.footer_bottom__inner {
	border-bottom: 1px solid rgba($color: $light, $alpha: 0.16);

	@include bp($point_4, min) {
		padding: 6rem var(--gl-indent) 6rem;
	}

	@include bp($point_4 - 1) {
		padding: 4rem var(--gl-indent);
	}

	p {
		display: block;
		margin: 0 0 2rem 0;
		color: #707070;
		font-size: 1.6rem;
		line-height: 2rem;
		letter-spacing: 0.53px;
		font-weight: 300;

		&:last-child {
			margin-bottom: 0;
		}

		@include bp($point_4, min) {
			font-size: 1.6rem;
		}

		@include bp($point_4 - 1) {
			font-size: 1.3rem;
		}
	}
}

.footer_copyright {
	padding: 3rem var(--gl-indent) 3rem;

	.footer_container {
		@extend %global_flex_block_row_wrap_flex-start_center;
	}

	@include bp($point_4) {
		padding: 5.5rem var(--gl-indent) 8rem;
	}
}

.footer_copyright__text {
	color: rgba($color: $light, $alpha: 0.66);

	@include bp($point_4, min) {
		margin: 0 5rem 1rem 0;
		font-size: 1.2rem;
		letter-spacing: 0.53px;
	}

	@include bp($point_4 - 1) {
		width: 100%;
		order: 2;
		font-size: 1.1rem;
		letter-spacing: 0.48px;
	}
}

.footer_copyright__text span {
	@include bp($point_4, min) {
		&:not(:last-child) {
			&:after {
				content: '-';
				padding: 0 0.3rem;
			}
		}
	}

	@include bp($point_4 - 1) {
		display: block;
	}

}

.footer_copyright__list {
	display: flex;
	align-items: baseline;
	flex-wrap: wrap;

	@include bp($point_4) {
		margin: 0 0 4.5rem 0;
	}
}

.footer_copyright__item {
	margin: 0 0 1rem 0;

	&:not(:last-child) {
		padding-right: 2.9rem;
		position: relative;

		&:after {
			content: "|";
			position: absolute;
			top: calc(50% + 1px);
			right: 1.2rem;
			transform: translateY(-50%);
			font-size: 1.2rem;
			color: $light;

		}
	}

}

.footer_copyright__link {
	font-size: 1.2rem;
	color: rgba($color: $light, $alpha: 0.66);
	text-decoration: underline;
	transition: color .3s ease;

	&:hover {
		color: $light;
	}

	@include bp($point_4 - 1) {
		font-weight: 300;
	}
}

.footer_line {
	display: none;
	position: absolute;
	left: -2.4rem;
	bottom: 0;
	width: 1rem;
	height: calc(100% + 10rem);

	@include bp($point_1, min) {
		display: block;
	}
}

.footer_line__inner {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba($color: $sand_2, $alpha: 0.1);
}