.leoni_photo {
	@include bp($point_2, min) {
		padding: 17rem var(--gl-indent) 9rem;
	}

	@include bp($point_3) {
		padding: 9rem var(--gl-indent);
	}
}

.leoni_photo__in {
	position: relative;
}

//======================= DECOR

.leoni_photo__decor {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}



.leoni_photo__line_y,
.leoni_photo__line_x {
	background: rgba($sand_2, .1);
}

.leoni_photo__line_y {
	position: absolute;
	bottom: -6rem;
	height: 85%;

	@include bp($point_4, min) {
		left: 37%;
		width: 1rem;
	}
	@include bp($point_4 - 1) {
		display: none;
	}
}

.leoni_photo__line_x {
	position: absolute;

	@include bp($point_4, min) {
		left: -74%;
		top: 84.5%;
		height: 1rem;
		width: 100%;
	}
	@include bp($point_4 - 1) {
		left: -90%;
		bottom: -10.4%;
		height: .6rem;
		width: 100vw;
	}
}

//======================== DECOR/


.leoni_photo__title {
	@extend %h2;

	@include bp($point_2, min) {
		max-width: 90rem;
		margin-bottom: 19.4rem;
	}

	@include bp($point_3) {
		margin-bottom: 2rem;
		line-height: 1.46;
	}
}

.leoni_photo__row {
	@include bp($point_2, min) {
		@include flex_block(row-reverse, nowrap, flex-start);
	}
}

.leoni_photo__info_w {
	@include bp($point_2, min) {
		width: 42.5%;
	}
}

.leoni_photo__slider_w {
	position: relative;
	@include bp($point_2, min) {
		// width: calc(57.5% + 20.8rem);
		// margin-right: -20.8rem;
		width: 57.5%;
	}

	@include bp($point_3) {
		// margin-right: -20.8rem;
		margin-bottom: 2rem;
	}
}

//===================== INFO

.leoni_photo__info {
	padding-top: 3.4rem;

	@include bp($point_2, min) {
		max-width: 50rem;
		padding-right: 2rem;
	}
}

.leoni_photo__info_title {
	@extend %h3;

	@include bp($point_2, min) {
		margin-bottom: 2.4rem;
	}

	@include bp($point_3) {
		margin-bottom: 2.8rem;
	}
}

.leoni_photo__descr {
	font-weight: 300;

	p {
		@include bp($point_2, min) {
			padding-bottom: 1.8rem;
		}

		@include bp($point_3) {
			padding-bottom: 1.6rem;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}

	i {
		font-weight: 400;
	}

	@include bp($point_4, min) {
		margin-bottom: 4.6rem;

		font-size: 1.8rem;
		letter-spacing: 0.78px;
		line-height: 1.72;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 4.4rem;

		font-size: 1.5rem;
		letter-spacing: 0.65px;
		line-height: 1.6;
	}
}

.leoni_photo__link {
	@extend %accent_btn;
	@extend %btn_size_1;
}

//==================== SLIDER

.leoni_photo__slider {
	position: relative;

	width: 100%;
	width: calc(100% + 20.8rem);

	@include bp($point_4, min) {
		margin-bottom: 1.4rem;
	}

	@include bp($point_4 - 1) {
		margin-bottom: .8rem;
	}
}

.slick-slide {
	opacity: .3;
	transition: opacity ease-in-out .3s;

	&.slick-current {
		opacity: 1;
	}
}

.leoni_photo__slider_item {
	height: 100%;

	@include bp($point_4, min) {
		padding-right: 3rem;
	}
}

.leoni_photo__slider_img {
	position: relative;

	display: block;
	width: 100%;

	filter: sepia(.5);

	.slick-slide.slick-current & {
		// &::after {
		// 	transition: opacity ease-in-out .3s;
		// 	opacity: 0;
		// }
		filter: sepia(0);
	}

	// &::after {
	// 	position: absolute;
	// 	z-index: 1;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-color: rgba($sand_2, .4);
	// 	content: "";
	// }

	@include bp($point_4, min) {
		height: max(43rem, 33.7vw);
	}
	@include bp($point_4 - 1) {
		height: max(25.2rem, 50vw);
		padding-right: 2rem;
    margin-right: -2.6rem;
	}
}

.leoni_photo__slider_img_in {
	@extend %cover_img;
}

//==================== Arrows

.leoni_photo__arrows_w {
	@extend %global_flex_block_row_wrap_flex-start_center;
	position: absolute;
	z-index: 100;


	@include bp(1800px, min) {
		right: -15%;
		top: 40.4%;

		width: 22.4rem;
		height: 8rem;

	}

	@media (min-width: 1024px ) and (max-width: 1799px) {
		right: -2rem;
		bottom: -2.2rem;

		width: 22.4rem;
		height: 8rem;
	}

	@include bp($point_3) {
		right: -2rem;
		bottom: 0;

		width: 10.6rem;
		height: 2.6rem;
	}

}

.leoni_photo__slider_prev,
.leoni_photo__slider_next {
	background: url('../images/arrow.svg') no-repeat left center;

	cursor: pointer;
	transition: transform ease-in-out .2s;

	@include bp($point_2, min) {
		width: 11.2rem;
		height: 100%;
		background-size: 26.4rem 4.6rem;
	}

	@include bp($point_3) {
		bottom: -3.2rem;

		width: 5.3rem;
		height: 2.6rem;
		background-size: 17rem 2.6rem;
	}
}

.leoni_photo__slider_prev {
	&:hover {
		transform: translateX(3px);
	}

	@include bp($point_2, min) {
		right: 19rem;
	}

	@include bp($point_3) {
		right: 6rem;
	}
}

.leoni_photo__slider_next {
	transform: rotate(180deg);

	&:hover {
		transform: translateX(-3px) rotate(180deg);
	}

	@include bp($point_2, min) {
		right: 8.2rem;
	}

	@include bp($point_3) {
		right: 1rem;
	}
}

//============= Counter

.leoni_photo__slider_counter {
	@extend %global_flex_block_row_wrap_flex-start;
	padding-left: 1.2rem;
}

.leoni_photo__slider_curent {
	font-weight: 500;
	letter-spacing: 1.04;
	color: $dark;

	@include bp($point_4, min) {
		font-size: 2.4rem;
	}

	@include bp($point_4 - 1) {
		font-size: 1.6rem;
	}
}

.leoni_photo__slider_total {
	position: relative;
	padding-left: 1rem;

	font-weight: 200;
	letter-spacing: 0.61px;
	color: $dark;

	&::after {
		position: absolute;
		left: .4rem;

		content: '|';
	}

	@include bp($point_4, min) {
		font-size: 1.4rem;
	}

	@include bp($point_4 - 1) {
		font-size: 1.2rem;
	}
}
