%cover_img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

%contain_img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: contain;
}

%title {
  font-family: $font_1;
  font-size: 4.8rem;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.2rem;
  // letter-spacing: 0.04rem;
  text-align: left;
  color: $dark;
}

%full_width_tablet {
	@include bp($point_4){
		width: calc(100% + (var(--gl-indent) * 2));
		margin: 0 calc(var(--gl-indent) * -1);
	}
}