//---------------------------------------------sections
.section {
	position: relative;

	padding: 5rem var(--gl-indent);

	width: 100%;

	font-size: 1rem;

	&.section--bg_mod {
		background-color: $white;
	}

	&.section--zona_mod {
		padding: 8.6rem var(--gl-indent) 16rem;
	}
}

.section__in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;
}
.section--map {
	@include bp($point_4){
		padding: 0 var(--gl-indent);
	}
}