//------------------------------------------------------------base styles
:root {
	--full-screen: calc(var(--vh, 1vh) * 100);

	--height-footer: 21.6rem;
	--gl-indent: 3rem;

	@include bp($point_1 + 1,min) {
		--height-header: 21.4rem;
	}

	@include bp($point_1) {
		--height-header: 10.8rem;
	}
}

html {
	height: 100%;
	font: 400 10px/1.33 $font_1;

	@include bp($point_0, min) {
		font-size: calc(100vw / 1920 * 10);
	}

	@media (min-width: $point_9) and (max-width: $point_0 - 1) {
		font-size: 10px;
	}

	@include bp($point_9 - 1) {
		font-size: calc(100vw / 390 * 10);
	}


}

body {
	position: relative;

	display: flex;
	height: 100%;

	font-size: $font_size_base;
	color: $color_text_base;

	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: $body_bg;

	&.body--open_menu_state {
		overflow: hidden;
	}

	@supports (display: grid) {
		display: block;
	}
}

.wrapper {
	position: relative;

	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: auto !important;

	overflow-x: hidden;
}

.base {
	width: 100%;
	position: relative;
	padding: var(--height-header) 0 0;

	flex: 1 0 auto;
}

a {
	text-decoration: none;
	color: $color_link_base;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

}

.elements_list {
	padding: 3rem;
}

//------------------------------------------------------------base styles###
