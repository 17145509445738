.cards {
	@include bp($point_4, min) {
		padding: 8rem var(--gl-indent);
	}

	@include bp($point_4 - 1) {
		padding: 7.8rem var(--gl-indent);
	}
}

.cards__in {
	position: relative;
}

//========================== Decor

.cards__decor {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.cards__line_y_1,
.cards__line_y_2 {
	background: rgba($sand_2, .1);

	@include bp($point_4, min) {
		width: 1rem;
	}

	@include bp($point_4 - 1) {
		width: .6rem;
	}
}

.cards__line_y_1 {
	position: absolute;

	@include bp($point_4, min) {
		top: -14.4rem;
		left: 37%;
		height: 54.2%;
	}

	@include bp($point_4 - 1) {
		top: -13rem;
		left: 88%;
		height: 61%;
	}
}

.cards__line_y_2 {
	position: absolute;
	height: calc(100% + -67.8rem);

	@include bp($point_4, min) {
		bottom: -8.2rem;
		right: 11.4%;
	}

	@include bp($point_4 - 1) {
		// top: 72%;
		// right: 11%;
		display: none;
	}
}

.cards__line_x {
	position: absolute;
	background: rgba($sand_2, .2);

	@include bp($point_4, min) {
		left: -60%;
		top: 89%;
		height: 1rem;
		width: 100%;
	}

	@include bp($point_4 - 1) {
		left: 0%;
		top: 73%;
		height: .6rem;
		width: 100vw;
	}
}

//========================== Decor /

.cards__item {
	@include flex_block(row, wrap, flex-start, stretch);
	position: relative;

	height: 100%;

	&:nth-child(even) {
		flex-direction: row-reverse;
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include bp($point_4, min) {
		margin: 0 -3rem 21.6rem 0;
	}

	@include bp($point_4 - 1) {
		margin: 0 -3rem 11.6rem 0;
	}
}

.cards__col {
	@include bp($point_4, min) {
		width: 50%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
	}

	padding-right: 3rem;
}

.cards__img {
	position: relative;
	display: block;

	// height: 100%;
	width: 100%;
	height: max(37.8rem, 33.8vw);

	max-width: 48rem;
	// max-height: 64.8rem;


	.cards__item:nth-child(odd) & {
		margin-left: auto;

		@include bp($point_4 - 1) {
			padding-right: 5.8rem;
		}
	}

	.cards__item:nth-child(even) & {
		@include bp($point_4 - 1) {
			padding-left: 5.8rem;
		}
	}
}

.cards__img_in {
	@extend %cover_img;
}

.cards__img_in_trilocali {
	transform: scale(2.1);
	transform-origin: 20% 80%;
}

.cards__img_in_quadrilocali {
	width: 170%;
	transform: scale(3.3);
	transform-origin: 20% 65%;
}

.cards__content_w {
	width: 100%;
	max-width: 60.8rem;

	.cards__item:nth-child(even) & {
		margin-left: auto;

		@include bp($point_4 - 1) {
			padding: 3.2rem 1rem 0 7rem;
		}
	}

	.cards__item:nth-child(odd) & {
		@include bp($point_4 - 1) {
			padding: 3.2rem 5rem 0 1rem;
		}
	}

	@include bp($point_4, min) {
		padding-top: 3.2rem;
	}

}

.cards__title {
	@extend %h3;

	@include bp($point_4, min) {
		margin-bottom: 3.4rem;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 1rem;
	}
}

.cards__descr {
	@extend %descr;

	@include bp($point_4, min) {
		margin-bottom: 11rem;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 1.4rem;
	}
}

.cards__link {
	@extend %accent_btn;
	@extend %btn_size_1;
}