%form_el_label {
  display: block;

  margin-bottom: .4rem;
  color: $input_placeholder_color;
  font-weight: 300;
  letter-spacing: 1px;
  @include bp($point_4, min) {
		height: 3.6rem;
    font-size: $input_font_size;
  }

  @include bp($point_4 - 1) {
    font-size: 2rem;
  }
}

%form_el_default {
  display: block;
  width: 100%;
  padding: $input_offset;

  font-family: $font_1;
  font-weight: 300;
  letter-spacing: 1px;
  color: $input_text_color;
  box-shadow: inset 0 -2px 0 0px $input_border_color;
  border: none;
  border-radius: $input_radius;
  transition: box-shadow .3s linear;

  &::-webkit-input-placeholder {
    color: $input_placeholder_color;
  }

  &::-moz-placeholder {
    color: $input_placeholder_color;
  }

  &:-ms-input-placeholder {
    color: $input_placeholder_color;
  }

  &:hover,
  &:focus {
    box-shadow: inset 0 -2px 0 0px $input_border_color_active;
  }

  @include bp($point_4, min) {
    margin: 0 0 4rem 0;
    height: $input_height;
    font-size: $input_font_size;
  }

  @include bp($point_4 - 1) {
    margin: 0 0 4.4rem 0;
    height: 4.2rem;
    font-size: 2rem;
  }
}