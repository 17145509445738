.form_checkbox__block {
	display: block;
}

.form_checkbox__element {
	position: absolute;
	user-select: none;
	opacity: 0;
	pointer-events: none;
}

.form_checkbox__label {
	@extend %form_el_checkbox;
}