@font-face {
  font-family: "iconfont";
  src: url("../fonts/icons/iconfont.eot");
  src: url("../fonts/icons/iconfont.eot?#iefix") format("eot"), url("../fonts/icons/iconfont.woff") format("woff"), url("../fonts/icons/iconfont.ttf") format("truetype"), url("../fonts/icons/iconfont.svg#iconfont") format("svg");
}
.form_checkbox__label:after {
  content: "\e001";
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
}

.card_center__img_in, .leoni_photo__slider_img_in, .cards__img_in {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .map_descr, .map {
    width: calc(100% + var(--gl-indent) * 2);
    margin: 0 calc(var(--gl-indent) * -1);
  }
}

.btn_base, .card_center__link, .leoni_photo__link, .cards__link {
  color: #1e1e0a;
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom-color: #988b4f;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}
.btn_base:hover, .card_center__link:hover, .leoni_photo__link:hover, .cards__link:hover, .btn_base:focus, .card_center__link:focus, .leoni_photo__link:focus, .cards__link:focus {
  text-decoration: none;
}
.btn_base:hover, .card_center__link:hover, .leoni_photo__link:hover, .cards__link:hover {
  background-color: #988b4f;
  color: #fff;
}

.btn_base, .card_center__link, .leoni_photo__link, .cards__link {
  padding: 0 1.2rem;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .btn_base, .card_center__link, .leoni_photo__link, .cards__link {
    height: 5.8rem;
    min-width: 18rem;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 5.8rem;
    letter-spacing: 0.52px;
  }
}
@media screen and (max-width: 767px) {
  .btn_base, .card_center__link, .leoni_photo__link, .cards__link {
    height: 4.3rem;
    min-width: 15.4rem;
    font-size: 1.2rem;
    line-height: 4.3rem;
    font-weight: 600;
    letter-spacing: 0.52px;
  }
}

.leoni_photo__slider_counter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.leoni_photo__arrows_w, .zona, .footer_copyright .footer_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.header__in {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-end;
  align-items: flex-end;
}

.hero__block_head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: flex-start;
}

.menu_trigger:before, .header__logo, .form_radio__label:after, .form_checkbox__label:after {
  transition: opacity 0.3s ease;
  will-change: opacity;
}

.menu_trigger__decor, .menu_trigger__in:after, .menu_trigger__in:before {
  transition: background 0.3s ease;
  will-change: background;
}

.apartments_info__title, .leoni_photo__title, .zona_section__title {
  color: #1e1e0a;
  font-weight: 200;
}
@media screen and (min-width: 768px) {
  .apartments_info__title, .leoni_photo__title, .zona_section__title {
    font-size: 4.8rem;
    letter-spacing: 2.08px;
  }
}
@media screen and (max-width: 767px) {
  .apartments_info__title, .leoni_photo__title, .zona_section__title {
    font-size: 2.4rem;
    letter-spacing: 1px;
  }
}

.text_title, .card_center__title, .leoni_photo__info_title, .cards__title {
  color: #1e1e0a;
}
@media screen and (min-width: 768px) {
  .text_title, .card_center__title, .leoni_photo__info_title, .cards__title {
    font-size: 3rem;
    letter-spacing: 1.3px;
    font-weight: 400;
  }
}
@media screen and (max-width: 767px) {
  .text_title, .card_center__title, .leoni_photo__info_title, .cards__title {
    font-size: 2rem;
    letter-spacing: 0.87px;
    font-weight: 500;
  }
}

.card_center__descr, .zona_section__text, .cards__descr {
  font-weight: 200;
  color: #1e1e0a;
}
@media screen and (min-width: 768px) {
  .card_center__descr, .zona_section__text, .cards__descr {
    font-size: 2.4rem;
    letter-spacing: 1.04px;
    line-height: 1.5;
  }
}
@media screen and (max-width: 767px) {
  .card_center__descr, .zona_section__text, .cards__descr {
    font-size: 1.7rem;
    letter-spacing: 0.74px;
    line-height: 1.4;
  }
}

.form_select__label, .form_textarea__label, .form_input__label {
  display: block;
  margin-bottom: 0.4rem;
  color: rgba(30, 30, 10, 0.4);
  font-weight: 300;
  letter-spacing: 1px;
}
@media screen and (min-width: 768px) {
  .form_select__label, .form_textarea__label, .form_input__label {
    height: 3.6rem;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .form_select__label, .form_textarea__label, .form_input__label {
    font-size: 2rem;
  }
}

.form_select__element, .form_textarea__element, .form_input__element {
  display: block;
  width: 100%;
  padding: 0 0 1rem 0;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  color: #1e1e0a;
  box-shadow: inset 0 -2px 0 0px rgba(30, 30, 10, 0.3);
  border: none;
  border-radius: 0;
  transition: box-shadow 0.3s linear;
}
.form_select__element::-webkit-input-placeholder, .form_textarea__element::-webkit-input-placeholder, .form_input__element::-webkit-input-placeholder {
  color: rgba(30, 30, 10, 0.4);
}
.form_select__element::-moz-placeholder, .form_textarea__element::-moz-placeholder, .form_input__element::-moz-placeholder {
  color: rgba(30, 30, 10, 0.4);
}
.form_select__element:-ms-input-placeholder, .form_textarea__element:-ms-input-placeholder, .form_input__element:-ms-input-placeholder {
  color: rgba(30, 30, 10, 0.4);
}
.form_select__element:hover, .form_textarea__element:hover, .form_input__element:hover, .form_select__element:focus, .form_textarea__element:focus, .form_input__element:focus {
  box-shadow: inset 0 -2px 0 0px #000;
}
@media screen and (min-width: 768px) {
  .form_select__element, .form_textarea__element, .form_input__element {
    margin: 0 0 4rem 0;
    height: 3.6rem;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .form_select__element, .form_textarea__element, .form_input__element {
    margin: 0 0 4.4rem 0;
    height: 4.2rem;
    font-size: 2rem;
  }
}

.form_checkbox__label {
  display: block;
  position: relative;
  padding-left: 4.2rem;
  font-size: 1.3rem;
  letter-spacing: 0.54px;
  color: #1e1e0a;
  cursor: pointer;
}
.form_checkbox__label a {
  text-decoration: underline;
}
.form_checkbox__label:before, .form_checkbox__label:after {
  position: absolute;
  top: 50%;
  transform: translateY(-1.34rem);
  left: 0;
  width: 2.7rem;
  height: 2.7rem;
  cursor: pointer;
}
.form_checkbox__label:before {
  content: "";
  border-radius: 2px;
  border: 2px solid rgba(30, 30, 10, 0.3);
}
.form_checkbox__element:checked + .form_checkbox__label:before {
  border: 2px solid #1e1e0a;
}
.form_checkbox__label:after {
  text-align: center;
  line-height: 2.7rem;
  opacity: 0;
  color: #000;
}
.form_checkbox__element:checked + .form_checkbox__label:after {
  opacity: 1;
}

.form_radio__label {
  position: relative;
  padding-left: 3rem;
  font-size: 1.6rem;
  color: #000;
  cursor: pointer;
}
.form_radio__label:before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  border: 1px solid #000;
  cursor: pointer;
}
.form_radio__label:after {
  content: "";
  position: absolute;
  top: 0.4rem;
  left: 0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background: #000;
  opacity: 0;
}
.form_radio__element:checked + .form_radio__label:after {
  opacity: 1;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}
html:before, html:after, body:before, body:after, div:before, div:after, span:before, span:after, object:before, object:after, iframe:before, iframe:after,
h1:before,
h1:after, h2:before, h2:after, h3:before, h3:after, h4:before, h4:after, h5:before, h5:after, h6:before, h6:after, p:before, p:after, blockquote:before, blockquote:after, pre:before, pre:after,
abbr:before,
abbr:after, address:before, address:after, cite:before, cite:after, code:before, code:after,
del:before,
del:after, dfn:before, dfn:after, em:before, em:after, img:before, img:after, ins:before, ins:after, kbd:before, kbd:after, q:before, q:after, samp:before, samp:after,
small:before,
small:after, strong:before, strong:after, var:before, var:after,
b:before,
b:after, i:before, i:after,
dl:before,
dl:after, dt:before, dt:after, dd:before, dd:after, ol:before, ol:after, ul:before, ul:after, li:before, li:after,
fieldset:before,
fieldset:after, form:before, form:after, label:before, label:after, legend:before, legend:after,
article:before,
article:after, aside:before, aside:after, canvas:before, canvas:after, details:before, details:after, figcaption:before, figcaption:after, figure:before, figure:after,
footer:before,
footer:after, header:before, header:after, hgroup:before, hgroup:after, menu:before, menu:after, nav:before, nav:after, section:before, section:after, summary:before, summary:after,
time:before,
time:after, mark:before, mark:after, audio:before, audio:after, video:before, video:after, button:before, button:after {
  box-sizing: border-box;
}

sub,
sup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  box-sizing: border-box;
}
sub:before, sub:after,
sup:before,
sup:after {
  box-sizing: border-box;
}

a:before, a:after {
  box-sizing: border-box;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: none;
}

q:before, q:after {
  content: none;
}

:focus {
  outline: 0;
}

input[type=text], textarea {
  -webkit-appearance: none;
}
input[type=text]::-ms-clear, textarea::-ms-clear {
  display: none;
}

input,
textarea {
  border-radius: 0;
  box-sizing: border-box;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(30, 30, 10, 0.4);
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: rgba(30, 30, 10, 0.4);
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(30, 30, 10, 0.4);
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  resize: none;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  word-wrap: break-word;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Italic.woff2") format("woff2"), url("../fonts/Poppins-Italic.woff") format("woff");
  font-weight: 400;
  font-style: "italic";
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraLight.woff2") format("woff2"), url("../fonts/Poppins-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Light.woff2") format("woff2"), url("../fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2"), url("../fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
:root {
  --full-screen: calc(var(--vh, 1vh) * 100);
  --height-footer: 21.6rem;
  --gl-indent: 3rem;
}
@media screen and (min-width: 1201px) {
  :root {
    --height-header: 21.4rem;
  }
}
@media screen and (max-width: 1200px) {
  :root {
    --height-header: 10.8rem;
  }
}

html {
  height: 100%;
  font: 400 10px/1.33 "Poppins", sans-serif;
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 0.5208333333vw;
  }
}
@media (min-width: 390px) and (max-width: 1919px) {
  html {
    font-size: 10px;
  }
}
@media screen and (max-width: 389px) {
  html {
    font-size: 2.5641025641vw;
  }
}

body {
  position: relative;
  display: flex;
  height: 100%;
  font-size: 1.8rem;
  color: #1e1e0a;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f3f3;
}
body.body--open_menu_state {
  overflow: hidden;
}
@supports (display: grid) {
  body {
    display: block;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto !important;
  overflow-x: hidden;
}

.base {
  width: 100%;
  position: relative;
  padding: var(--height-header) 0 0;
  flex: 1 0 auto;
}

a {
  text-decoration: none;
  color: #1e1e0a;
}
a:hover, a:focus {
  text-decoration: underline;
}

.elements_list {
  padding: 3rem;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--height-header);
  width: 100%;
  padding: 0 var(--gl-indent);
  font-size: 1rem;
  will-change: transform;
  z-index: 100;
}
@media screen and (min-width: 1201px) {
  .header {
    overflow: hidden;
  }
}
@media screen and (max-width: 1200px) {
  .header {
    padding-top: 8.2rem;
  }
}

.header__in {
  width: 100%;
  max-width: 142.4rem;
  height: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 1201px) {
  .header__in {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-end;
    align-items: flex-end;
  }
}

.header__logo {
  display: block;
}
@media screen and (min-width: 1201px) {
  .header__logo {
    width: 18.2rem;
  }
  .header__logo:hover, .header__logo:active, .header__logo:focus {
    opacity: 0.8;
  }
}
@media screen and (max-width: 1200px) {
  .header__logo {
    z-index: 210;
    position: relative;
    width: 12.5rem;
  }
}

.header__logo_in {
  display: block;
  width: 100%;
  height: auto;
}

@media screen and (min-width: 1800px) {
  .header__nav {
    margin-right: -17rem;
  }
}
@media screen and (min-width: 1201px) {
  .header__nav {
    position: relative;
    margin-left: auto;
    padding-bottom: 3.8rem;
  }
  .header__nav.header__nav--mobile_mod {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .header__nav.header__nav--desktop_mod {
    display: none;
  }
  .header__nav.header__nav--mobile_mod {
    opacity: 0.98;
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--full-screen);
    max-height: var(--full-screen);
    padding: 13rem var(--gl-indent);
    background-color: #988b4f;
    transform: translateY(-101%);
    transition: transform 0.2s ease;
    will-change: transform;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  body.body--open_menu_state .header__nav.header__nav--mobile_mod {
    transform: translateY(0);
  }
}
@media (max-height: 480px) {
  .header__nav.header__nav--mobile_mod {
    display: block;
    padding-top: 18rem;
  }
}

.header_decor {
  position: absolute;
  left: -1.4rem;
  right: -30vw;
  bottom: 0;
  height: 1rem;
  overflow: hidden;
}
@media screen and (max-width: 1200px) {
  .header_decor {
    display: none;
  }
}

.header_decor__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(161, 130, 2, 0.1);
}

.nav_list {
  padding: 0;
  list-style: none;
}
@media screen and (min-width: 1201px) {
  .nav_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin: 0 -5.4rem -1rem 0;
  }
}
@media screen and (max-width: 1200px) {
  .nav_list {
    width: 100%;
  }
}

@media screen and (min-width: 1201px) {
  .nav_list__item {
    padding: 0 5.4rem 1rem 0;
  }
}
@media screen and (max-width: 1200px) {
  .nav_list__item {
    width: 100%;
    max-width: 18rem;
    margin: 0 auto;
    padding-bottom: 2rem;
    border-bottom: 1px solid #f5f2e4;
    text-align: center;
  }
  .nav_list__item:not(:last-child) {
    margin-bottom: 2.4rem;
  }
}

.nav_list__link {
  position: relative;
  text-decoration: none;
  letter-spacing: 0.06rem;
  line-height: 0.8;
  text-transform: uppercase;
  transition: color ease-in-out 0.3s;
}
.nav_list__link:hover, .nav_list__link:active, .nav_list__link:focus {
  text-decoration: none;
}
@media screen and (min-width: 1200px) {
  .nav_list__link:hover, .nav_list__link:active, .nav_list__link:focus {
    color: #a18202;
  }
}
@media screen and (max-width: 1199px) {
  .nav_list__link:hover, .nav_list__link:active, .nav_list__link:focus {
    color: #bfbfbf;
  }
}
@media screen and (min-width: 1201px) {
  .nav_list__link {
    font-weight: 400;
    font-size: 1.4rem;
    color: #1E1E0A;
  }
}
@media screen and (max-width: 1200px) {
  .nav_list__link {
    font-weight: 500;
    font-size: 1.6rem;
    color: #f5f2e4;
  }
}

.menu_trigger {
  z-index: 210;
  position: fixed;
  top: 3.4rem;
  right: var(--gl-indent);
  height: 2.2rem;
  width: 2.2rem;
  cursor: pointer;
  transition: transform ease-in-out 0.3s;
}
body.body--scroll_state .menu_trigger {
  transform: translate(0, 5rem);
}
.menu_trigger:before {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 6rem;
  height: 6rem;
  margin: -3rem 0 0 -3rem;
  border-radius: 50%;
  background-color: #988b4f;
  opacity: 0;
  pointer-events: none;
}
body.body--scroll_state .menu_trigger:before {
  opacity: 1;
}
@media screen and (min-width: 1201px) {
  .menu_trigger {
    display: none;
  }
}

.menu_trigger__in {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
}
.menu_trigger__in:before {
  content: "";
  transform: translate(0, -0.7rem);
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  background-color: #988b4f;
}
body.body--open_menu_state .menu_trigger__in:before, body.body--scroll_state .menu_trigger__in:before {
  background-color: #fff;
}
.menu_trigger__in:after {
  content: "";
  transform: translate(0, 0.7rem);
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  background-color: #988b4f;
}
body.body--open_menu_state .menu_trigger__in:after, body.body--scroll_state .menu_trigger__in:after {
  background-color: #fff;
}
body.body--open_menu_state .menu_trigger__in:before {
  transform: rotate(45deg) translate(0, 0);
}
body.body--open_menu_state .menu_trigger__in:after {
  transform: rotate(-45deg) translate(0, 0);
}

.menu_trigger__decor {
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  background-color: #988b4f;
}
body.body--open_menu_state .menu_trigger__decor {
  opacity: 0;
  transform: translate(100%, 0);
}
body.body--open_menu_state .menu_trigger__decor, body.body--scroll_state .menu_trigger__decor {
  background-color: #fff;
}

.nav_logo {
  position: absolute;
  top: 3.4rem;
  left: var(--gl-indent);
  display: block;
  width: 12.4rem;
  height: 7.4rem;
}
@media screen and (min-width: 1201px) {
  .nav_logo {
    display: none;
  }
}

.nav_logo__in {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer {
  flex: 0 0 auto;
  width: 100%;
  min-height: var(--height-footer);
  background-color: #fff;
  font-size: 1rem;
  will-change: transform;
}

.footer_main {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .footer_main {
    padding: 8.5rem var(--gl-indent) 12.4rem;
  }
}
@media screen and (min-width: 768px) {
  .footer_main {
    padding: 11rem var(--gl-indent) 19.3rem;
  }
}
@media screen and (min-width: 1200px) {
  .footer_main {
    width: 156.4rem;
    display: flex;
    align-items: baseline;
  }
}
@media screen and (min-width: 768px) {
  .footer_main .text_title {
    margin: 0 8.2rem 5.8rem 0;
  }
}
@media screen and (max-width: 767px) {
  .footer_main .text_title {
    margin: 0 0 6.2rem 0;
  }
}

.footer_container {
  max-width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 1200px) {
  .footer_container {
    width: 155rem;
    display: flex;
    align-items: baseline;
  }
}

.footer_links {
  display: block;
}
@media screen and (min-width: 768px) {
  .footer_links {
    margin: 0 10rem 7.4rem 0;
  }
}
@media screen and (max-width: 767px) {
  .footer_links {
    margin: 0 0 7.7rem 0;
  }
}

.footer_links__item {
  display: block;
  margin: 0 0 3.5rem 0;
}
.footer_links__item:last-child {
  margin-bottom: 0;
}

.footer_links__link {
  display: inline-block;
  padding: 0 0 1.2rem 0;
  border-bottom: 2px solid rgba(152, 139, 79, 0.5);
  transition: border-bottom-color 0.3s linear;
  font-weight: 300;
  letter-spacing: 0.87px;
}
.footer_links__link:hover, .footer_links__link:focus {
  border-bottom-color: #1e1e0a;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .footer_links__link {
    height: 3.5rem;
    font-size: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .footer_links__link {
    height: 4.5rem;
    font-size: 3rem;
  }
}

.footer_form {
  max-width: 100%;
  margin: -0.4rem auto 0 0;
}
.footer_form .form_checkbox {
  margin-bottom: 4.6rem;
}
@media screen and (min-width: 768px) {
  .footer_form .form_checkbox__label {
    line-height: 1.7;
  }
}
@media screen and (max-width: 1200px) {
  .footer_form {
    width: 100%;
  }
}
@media screen and (min-width: 1201px) {
  .footer_form {
    width: 48rem;
  }
}

.footer_bottom {
  background-color: #0f0f05;
}
@media screen and (min-width: 768px) {
  .footer_bottom .footer_container {
    width: 150rem;
  }
}

.footer_bottom__inner {
  border-bottom: 1px solid rgba(245, 242, 228, 0.16);
}
@media screen and (min-width: 768px) {
  .footer_bottom__inner {
    padding: 6rem var(--gl-indent) 6rem;
  }
}
@media screen and (max-width: 767px) {
  .footer_bottom__inner {
    padding: 4rem var(--gl-indent);
  }
}
.footer_bottom__inner p {
  display: block;
  margin: 0 0 2rem 0;
  color: #707070;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.53px;
  font-weight: 300;
}
.footer_bottom__inner p:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .footer_bottom__inner p {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 767px) {
  .footer_bottom__inner p {
    font-size: 1.3rem;
  }
}

.footer_copyright {
  padding: 3rem var(--gl-indent) 3rem;
}
@media screen and (max-width: 768px) {
  .footer_copyright {
    padding: 5.5rem var(--gl-indent) 8rem;
  }
}

.footer_copyright__text {
  color: rgba(245, 242, 228, 0.66);
}
@media screen and (min-width: 768px) {
  .footer_copyright__text {
    margin: 0 5rem 1rem 0;
    font-size: 1.2rem;
    letter-spacing: 0.53px;
  }
}
@media screen and (max-width: 767px) {
  .footer_copyright__text {
    width: 100%;
    order: 2;
    font-size: 1.1rem;
    letter-spacing: 0.48px;
  }
}

@media screen and (min-width: 768px) {
  .footer_copyright__text span:not(:last-child):after {
    content: "-";
    padding: 0 0.3rem;
  }
}
@media screen and (max-width: 767px) {
  .footer_copyright__text span {
    display: block;
  }
}

.footer_copyright__list {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .footer_copyright__list {
    margin: 0 0 4.5rem 0;
  }
}

.footer_copyright__item {
  margin: 0 0 1rem 0;
}
.footer_copyright__item:not(:last-child) {
  padding-right: 2.9rem;
  position: relative;
}
.footer_copyright__item:not(:last-child):after {
  content: "|";
  position: absolute;
  top: calc(50% + 1px);
  right: 1.2rem;
  transform: translateY(-50%);
  font-size: 1.2rem;
  color: #f5f2e4;
}

.footer_copyright__link {
  font-size: 1.2rem;
  color: rgba(245, 242, 228, 0.66);
  text-decoration: underline;
  transition: color 0.3s ease;
}
.footer_copyright__link:hover {
  color: #f5f2e4;
}
@media screen and (max-width: 767px) {
  .footer_copyright__link {
    font-weight: 300;
  }
}

.footer_line {
  display: none;
  position: absolute;
  left: -2.4rem;
  bottom: 0;
  width: 1rem;
  height: calc(100% + 10rem);
}
@media screen and (min-width: 1200px) {
  .footer_line {
    display: block;
  }
}

.footer_line__inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(161, 130, 2, 0.1);
}

.form_textarea__label {
  margin: 0 0 0.2rem 0;
}

.form_textarea__element {
  padding: 2rem;
  box-shadow: inset 0 0 0 2px rgba(30, 30, 10, 0.3);
}
.form_textarea__element:hover, .form_textarea__element:focus {
  box-shadow: inset 0 0 0 2px #000;
}
@media screen and (min-width: 768px) {
  .form_textarea__element {
    height: 20rem;
  }
}
@media screen and (max-width: 767px) {
  .form_textarea__element {
    height: 21.4rem;
  }
}

.form_checkbox__block {
  display: block;
}

.form_checkbox__element {
  position: absolute;
  user-select: none;
  opacity: 0;
  pointer-events: none;
}

.form_radio__block {
  display: block;
}

.form_radio__element {
  display: none;
}

.form_success {
  background: rgba(152, 139, 79, 0.5);
  color: #1e1e0a;
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 50vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  display: none;
  animation: fade 0.3s linear;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.form_success .form_success_img {
  width: 8%;
}
.form_success .close_popup {
  position: absolute;
  top: -15px;
  right: -15px;
  padding: 7px 10px;
  background-color: white;
  border: 1px solid #1e1e0a;
  border-radius: 50px;
  cursor: pointer;
}
.form_success .close_popup .close_popup_img {
  width: 1rem;
}
.form_success .form_success_title {
  margin-top: 50px;
  font-size: 30px;
  text-align: center;
  font-weight: 400 !important;
}

.form_success_display {
  display: flex;
}

.form_col {
  margin: 0 0 3.4rem 0;
}
.form_col:last-child {
  margin-bottom: 0;
}

.section {
  position: relative;
  padding: 5rem var(--gl-indent);
  width: 100%;
  font-size: 1rem;
}
.section.section--bg_mod {
  background-color: #fff;
}
.section.section--zona_mod {
  padding: 8.6rem var(--gl-indent) 16rem;
}

.section__in {
  max-width: 150.4rem;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .section--map {
    padding: 0 var(--gl-indent);
  }
}

@media screen and (min-width: 768px) {
  .cards {
    padding: 8rem var(--gl-indent);
  }
}
@media screen and (max-width: 767px) {
  .cards {
    padding: 7.8rem var(--gl-indent);
  }
}

.cards__in {
  position: relative;
}

.cards__decor {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cards__line_y_1,
.cards__line_y_2 {
  background: rgba(161, 130, 2, 0.1);
}
@media screen and (min-width: 768px) {
  .cards__line_y_1,
.cards__line_y_2 {
    width: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .cards__line_y_1,
.cards__line_y_2 {
    width: 0.6rem;
  }
}

.cards__line_y_1 {
  position: absolute;
}
@media screen and (min-width: 768px) {
  .cards__line_y_1 {
    top: -14.4rem;
    left: 37%;
    height: 54.2%;
  }
}
@media screen and (max-width: 767px) {
  .cards__line_y_1 {
    top: -13rem;
    left: 88%;
    height: 61%;
  }
}

.cards__line_y_2 {
  position: absolute;
  height: calc(100% - 67.8rem);
}
@media screen and (min-width: 768px) {
  .cards__line_y_2 {
    bottom: -8.2rem;
    right: 11.4%;
  }
}
@media screen and (max-width: 767px) {
  .cards__line_y_2 {
    display: none;
  }
}

.cards__line_x {
  position: absolute;
  background: rgba(161, 130, 2, 0.2);
}
@media screen and (min-width: 768px) {
  .cards__line_x {
    left: -60%;
    top: 89%;
    height: 1rem;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .cards__line_x {
    left: 0%;
    top: 73%;
    height: 0.6rem;
    width: 100vw;
  }
}

.cards__item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  position: relative;
  height: 100%;
}
.cards__item:nth-child(even) {
  flex-direction: row-reverse;
}
.cards__item:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .cards__item {
    margin: 0 -3rem 21.6rem 0;
  }
}
@media screen and (max-width: 767px) {
  .cards__item {
    margin: 0 -3rem 11.6rem 0;
  }
}

.cards__col {
  padding-right: 3rem;
}
@media screen and (min-width: 768px) {
  .cards__col {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .cards__col {
    width: 100%;
  }
}

.cards__img {
  position: relative;
  display: block;
  width: 100%;
  height: max(37.8rem, 33.8vw);
  max-width: 48rem;
}
.cards__item:nth-child(odd) .cards__img {
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .cards__item:nth-child(odd) .cards__img {
    padding-right: 5.8rem;
  }
}
@media screen and (max-width: 767px) {
  .cards__item:nth-child(even) .cards__img {
    padding-left: 5.8rem;
  }
}

.cards__img_in_trilocali {
  transform: scale(2.1);
  transform-origin: 20% 80%;
}

.cards__img_in_quadrilocali {
  width: 170%;
  transform: scale(3.3);
  transform-origin: 20% 65%;
}

.cards__content_w {
  width: 100%;
  max-width: 60.8rem;
}
.cards__item:nth-child(even) .cards__content_w {
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .cards__item:nth-child(even) .cards__content_w {
    padding: 3.2rem 1rem 0 7rem;
  }
}
@media screen and (max-width: 767px) {
  .cards__item:nth-child(odd) .cards__content_w {
    padding: 3.2rem 5rem 0 1rem;
  }
}
@media screen and (min-width: 768px) {
  .cards__content_w {
    padding-top: 3.2rem;
  }
}

@media screen and (min-width: 768px) {
  .cards__title {
    margin-bottom: 3.4rem;
  }
}
@media screen and (max-width: 767px) {
  .cards__title {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .cards__descr {
    margin-bottom: 11rem;
  }
}
@media screen and (max-width: 767px) {
  .cards__descr {
    margin-bottom: 1.4rem;
  }
}

@media (min-width: 1200px) and (max-width: 150.4rem) {
  .zona {
    min-height: 97.6rem;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .zona {
    min-height: 80rem;
    padding: 8rem var(--gl-indent);
  }
}
@media screen and (max-width: 767px) {
  .zona {
    padding: 8.6rem var(--gl-indent) 15.5rem;
  }
}

.zona__decor {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.zona__line_y {
  position: absolute;
  bottom: 0;
  height: 5.6%;
  background: rgba(161, 130, 2, 0.1);
}
@media screen and (min-width: 768px) {
  .zona__line_y {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .zona__line_y {
    left: 82%;
    width: 0.6rem;
  }
}

.zona_section__header {
  display: flex;
}
@media screen and (min-width: 768px) {
  .zona_section__header {
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 7.1rem;
  }
}
@media screen and (max-width: 767px) {
  .zona_section__header {
    flex-direction: column;
    margin-bottom: 6.4rem;
  }
}

@media screen and (min-width: 768px) {
  .zona_section__text {
    width: 19.4rem;
  }
}
@media screen and (max-width: 767px) {
  .zona_section__text {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
}

@media screen and (min-width: 768px) {
  .zona_section__title {
    max-width: 99rem;
    margin-bottom: 9.8rem;
  }
}
@media screen and (max-width: 767px) {
  .zona_section__title {
    margin-bottom: 7rem;
  }
}

.zona_section__title strong {
  font-weight: 400;
  letter-spacing: normal;
}

.zona_section__devider {
  position: relative;
}
@media screen and (min-width: 768px) {
  .zona_section__devider {
    width: 107.2rem;
    height: 1rem;
    top: 0.6rem;
    margin-right: -14%;
  }
}
@media screen and (max-width: 767px) {
  .zona_section__devider {
    width: 70rem;
    height: 0.5rem;
    margin-bottom: 7.5rem;
    margin-left: 6rem;
    order: -1;
  }
}
@media screen and (min-width: 149.4rem) {
  .zona_section__devider {
    margin-right: -20%;
  }
}

.zona_section__devider_img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(161, 130, 2, 0.1);
}

@media screen and (min-width: 768px) {
  .list_with_icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;
    margin: 0 -2.6rem -3rem 0;
    align-content: stretch;
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .list_with_icons {
    width: 83%;
    margin: 0 auto -7rem;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .list_with_icons__item {
    width: 50%;
    padding: 0 6rem 6rem;
  }
}
@media screen and (min-width: 1200px) {
  .list_with_icons__item {
    width: 25%;
    padding: 0 2.6rem 3rem 0;
  }
}
@media screen and (max-width: 767px) {
  .list_with_icons__item {
    width: 100%;
    padding: 0 0 7rem 0;
  }
}

.list_with_icons__wrap_img {
  position: relative;
}
@media screen and (min-width: 1199px) {
  .list_with_icons__wrap_img {
    width: 14.1rem;
    height: 12.8rem;
    margin-bottom: 1.2rem;
  }
}
@media screen and (max-width: 1200px) {
  .list_with_icons__wrap_img {
    width: 10.6rem;
    height: 9.6rem;
    margin: 0 auto 2.5rem;
  }
}

.list_with_icons__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.list_with_icons__descr {
  letter-spacing: 0.1rem;
  color: #1e1e0a;
}
@media screen and (min-width: 767px) {
  .list_with_icons__descr {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .list_with_icons__descr {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .list_with_icons__descr {
    font-size: 2rem;
    line-height: normal;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .hero {
    padding-top: 21.6rem;
  }
}
@media screen and (max-width: 767px) {
  .hero {
    padding: 9.2rem var(--gl-indent) 3.4rem;
  }
}

.hero__block {
  position: relative;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .hero__block {
    padding-left: 12.8rem;
  }
}

.hero__block_head {
  position: relative;
}
@media screen and (min-width: 768px) {
  .hero__block_head {
    padding-right: 6.8rem;
  }
  .hero__block_head:not(:last-child) {
    margin-bottom: 7.2rem;
  }
}
@media screen and (max-width: 767px) {
  .hero__block_head {
    margin-bottom: 6.2rem;
  }
}

.hero__title {
  width: 100%;
  max-width: 93rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1e1e0a;
}
@media screen and (min-width: 768px) {
  .hero__title {
    font-size: 4.8rem;
    letter-spacing: 0.2rem;
  }
}
@media screen and (max-width: 767px) {
  .hero__title {
    font-size: 2.4rem;
    letter-spacing: 0.76px;
  }
}

.hero__img_block {
  position: relative;
  width: 100%;
  padding-top: 54.9418604651%;
}
.hero__img_block::before {
  z-index: -1;
  position: absolute;
  bottom: 50%;
  content: "";
  width: 100vw;
  height: 200vh;
  transform: translateX(-50%);
  background-color: #fff;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .hero__img_block::before {
    left: calc(50% - 6.5rem);
  }
}
@media screen and (max-width: 767px) {
  .hero__img_block::before {
    left: 50%;
  }
}
@media screen and (max-width: 767px) {
  .hero__img_block {
    height: 50.4rem;
  }
}

.hero__img_block__in,
.hero__img_block__in_in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.parallaxImg {
  overflow: hidden;
}

.hero__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__block_decor {
  position: absolute;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .hero__block_decor {
    top: 0;
    left: 4.3rem;
    bottom: -10.6rem;
    width: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .hero__block_decor {
    z-index: -1;
    top: 17rem;
    left: 23.4rem;
    bottom: -8.6rem;
    width: 0.6rem;
  }
}

.hero__block_decor_x {
  position: absolute;
  top: 12.8rem;
  left: -3rem;
  right: 12rem;
  z-index: 0;
  height: 0.6rem;
}
@media screen and (min-width: 768px) {
  .hero__block_decor_x {
    display: none;
  }
}

.hero__block_decor__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(161, 130, 2, 0.1);
}

@media screen and (min-width: 1024px) {
  .leoni_photo {
    padding: 17rem var(--gl-indent) 9rem;
  }
}
@media screen and (max-width: 1023px) {
  .leoni_photo {
    padding: 9rem var(--gl-indent);
  }
}

.leoni_photo__in {
  position: relative;
}

.leoni_photo__decor {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.leoni_photo__line_y,
.leoni_photo__line_x {
  background: rgba(161, 130, 2, 0.1);
}

.leoni_photo__line_y {
  position: absolute;
  bottom: -6rem;
  height: 85%;
}
@media screen and (min-width: 768px) {
  .leoni_photo__line_y {
    left: 37%;
    width: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .leoni_photo__line_y {
    display: none;
  }
}

.leoni_photo__line_x {
  position: absolute;
}
@media screen and (min-width: 768px) {
  .leoni_photo__line_x {
    left: -74%;
    top: 84.5%;
    height: 1rem;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .leoni_photo__line_x {
    left: -90%;
    bottom: -10.4%;
    height: 0.6rem;
    width: 100vw;
  }
}

@media screen and (min-width: 1024px) {
  .leoni_photo__title {
    max-width: 90rem;
    margin-bottom: 19.4rem;
  }
}
@media screen and (max-width: 1023px) {
  .leoni_photo__title {
    margin-bottom: 2rem;
    line-height: 1.46;
  }
}

@media screen and (min-width: 1024px) {
  .leoni_photo__row {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }
}

@media screen and (min-width: 1024px) {
  .leoni_photo__info_w {
    width: 42.5%;
  }
}

.leoni_photo__slider_w {
  position: relative;
}
@media screen and (min-width: 1024px) {
  .leoni_photo__slider_w {
    width: 57.5%;
  }
}
@media screen and (max-width: 1023px) {
  .leoni_photo__slider_w {
    margin-bottom: 2rem;
  }
}

.leoni_photo__info {
  padding-top: 3.4rem;
}
@media screen and (min-width: 1024px) {
  .leoni_photo__info {
    max-width: 50rem;
    padding-right: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .leoni_photo__info_title {
    margin-bottom: 2.4rem;
  }
}
@media screen and (max-width: 1023px) {
  .leoni_photo__info_title {
    margin-bottom: 2.8rem;
  }
}

.leoni_photo__descr {
  font-weight: 300;
}
@media screen and (min-width: 1024px) {
  .leoni_photo__descr p {
    padding-bottom: 1.8rem;
  }
}
@media screen and (max-width: 1023px) {
  .leoni_photo__descr p {
    padding-bottom: 1.6rem;
  }
}
.leoni_photo__descr p:last-child {
  padding-bottom: 0;
}
.leoni_photo__descr i {
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .leoni_photo__descr {
    margin-bottom: 4.6rem;
    font-size: 1.8rem;
    letter-spacing: 0.78px;
    line-height: 1.72;
  }
}
@media screen and (max-width: 767px) {
  .leoni_photo__descr {
    margin-bottom: 4.4rem;
    font-size: 1.5rem;
    letter-spacing: 0.65px;
    line-height: 1.6;
  }
}

.leoni_photo__slider {
  position: relative;
  width: 100%;
  width: calc(100% + 20.8rem);
}
@media screen and (min-width: 768px) {
  .leoni_photo__slider {
    margin-bottom: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .leoni_photo__slider {
    margin-bottom: 0.8rem;
  }
}

.slick-slide {
  opacity: 0.3;
  transition: opacity ease-in-out 0.3s;
}
.slick-slide.slick-current {
  opacity: 1;
}

.leoni_photo__slider_item {
  height: 100%;
}
@media screen and (min-width: 768px) {
  .leoni_photo__slider_item {
    padding-right: 3rem;
  }
}

.leoni_photo__slider_img {
  position: relative;
  display: block;
  width: 100%;
  filter: sepia(0.5);
}
.slick-slide.slick-current .leoni_photo__slider_img {
  filter: sepia(0);
}
@media screen and (min-width: 768px) {
  .leoni_photo__slider_img {
    height: max(43rem, 33.7vw);
  }
}
@media screen and (max-width: 767px) {
  .leoni_photo__slider_img {
    height: max(25.2rem, 50vw);
    padding-right: 2rem;
    margin-right: -2.6rem;
  }
}

.leoni_photo__arrows_w {
  position: absolute;
  z-index: 100;
}
@media screen and (min-width: 1800px) {
  .leoni_photo__arrows_w {
    right: -15%;
    top: 40.4%;
    width: 22.4rem;
    height: 8rem;
  }
}
@media (min-width: 1024px) and (max-width: 1799px) {
  .leoni_photo__arrows_w {
    right: -2rem;
    bottom: -2.2rem;
    width: 22.4rem;
    height: 8rem;
  }
}
@media screen and (max-width: 1023px) {
  .leoni_photo__arrows_w {
    right: -2rem;
    bottom: 0;
    width: 10.6rem;
    height: 2.6rem;
  }
}

.leoni_photo__slider_prev,
.leoni_photo__slider_next {
  background: url("../images/arrow.svg") no-repeat left center;
  cursor: pointer;
  transition: transform ease-in-out 0.2s;
}
@media screen and (min-width: 1024px) {
  .leoni_photo__slider_prev,
.leoni_photo__slider_next {
    width: 11.2rem;
    height: 100%;
    background-size: 26.4rem 4.6rem;
  }
}
@media screen and (max-width: 1023px) {
  .leoni_photo__slider_prev,
.leoni_photo__slider_next {
    bottom: -3.2rem;
    width: 5.3rem;
    height: 2.6rem;
    background-size: 17rem 2.6rem;
  }
}

.leoni_photo__slider_prev:hover {
  transform: translateX(3px);
}
@media screen and (min-width: 1024px) {
  .leoni_photo__slider_prev {
    right: 19rem;
  }
}
@media screen and (max-width: 1023px) {
  .leoni_photo__slider_prev {
    right: 6rem;
  }
}

.leoni_photo__slider_next {
  transform: rotate(180deg);
}
.leoni_photo__slider_next:hover {
  transform: translateX(-3px) rotate(180deg);
}
@media screen and (min-width: 1024px) {
  .leoni_photo__slider_next {
    right: 8.2rem;
  }
}
@media screen and (max-width: 1023px) {
  .leoni_photo__slider_next {
    right: 1rem;
  }
}

.leoni_photo__slider_counter {
  padding-left: 1.2rem;
}

.leoni_photo__slider_curent {
  font-weight: 500;
  letter-spacing: 1.04;
  color: #1e1e0a;
}
@media screen and (min-width: 768px) {
  .leoni_photo__slider_curent {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .leoni_photo__slider_curent {
    font-size: 1.6rem;
  }
}

.leoni_photo__slider_total {
  position: relative;
  padding-left: 1rem;
  font-weight: 200;
  letter-spacing: 0.61px;
  color: #1e1e0a;
}
.leoni_photo__slider_total::after {
  position: absolute;
  left: 0.4rem;
  content: "|";
}
@media screen and (min-width: 768px) {
  .leoni_photo__slider_total {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .leoni_photo__slider_total {
    font-size: 1.2rem;
  }
}

.map {
  display: block;
  max-width: 100%;
  position: relative;
}
.map:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(245, 242, 228, 0.4);
  pointer-events: none;
}
@media (max-width: 1559px) and (min-width: 769px) {
  .map {
    height: 50rem;
    margin: 0 10rem 10rem -3rem;
  }
}
@media screen and (min-width: 1560px) {
  .map {
    height: 75.6rem;
    margin: 0 16rem 16.6rem calc((100vw - 0.5rem - 150.4rem) / -2);
  }
}
@media screen and (min-width: 769px) {
  .map {
    width: 55.7vw;
    order: 1;
  }
}
@media screen and (max-width: 768px) {
  .map {
    height: 38.2rem;
    max-width: none;
    order: 2;
  }
}

@media screen and (max-width: 767px) {
  .map__decor {
    position: absolute;
    z-index: 1;
    bottom: -8.2rem;
    left: 82%;
    height: 21.6%;
    width: 0.6rem;
    background: rgba(161, 130, 2, 0.1);
  }
}

.map__wrapper {
  display: flex;
}
@media screen and (max-width: 768px) {
  .map__wrapper {
    flex-wrap: wrap;
  }
}

.map__inner {
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
}

.map_descr {
  position: relative;
  padding: 3.4rem 0 0 0;
}
.map_descr .text_title {
  margin-bottom: 1.3rem;
}
@media screen and (max-width: 768px) {
  .map_descr .text_title {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 769px) {
  .map_descr {
    order: 2;
  }
}
@media screen and (max-width: 768px) {
  .map_descr {
    padding: 5.7rem 5rem 5rem 6.8rem;
    background-color: #fff;
  }
}

.map_descr__text {
  font-weight: 200;
}
@media screen and (min-width: 769px) {
  .map_descr__text {
    font-size: 3rem;
    line-height: 4.6rem;
    letter-spacing: 1.3px;
  }
}
@media screen and (max-width: 768px) {
  .map_descr__text {
    font-size: 2.4rem;
    letter-spacing: 1px;
  }
}

.map_line {
  display: none;
  position: absolute;
  top: -11rem;
  width: 1rem;
  height: 97rem;
}
@media screen and (min-width: 1560px) {
  .map_line {
    left: -8.4rem;
  }
}
@media screen and (min-width: 768px) {
  .map_line {
    display: block;
  }
}
@media (max-width: 1559px) and (min-width: 769px) {
  .map_line {
    left: -5.5rem;
  }
}

.map_line__inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(161, 130, 2, 0.1);
}

@media screen and (min-width: 768px) {
  .apartments_section {
    padding: 11rem var(--gl-indent) 0;
  }
}
@media screen and (max-width: 767px) {
  .apartments_section {
    padding: 12.4rem var(--gl-indent) 0;
  }
}

.apartments__decor {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.apartments__line_y {
  position: absolute;
  background: rgba(161, 130, 2, 0.1);
}
@media screen and (min-width: 768px) {
  .apartments__line_y {
    top: -11rem;
    left: 60%;
    height: 124%;
    width: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .apartments__line_y {
    top: 0;
    left: 82.2%;
    height: 10%;
    width: 0.6rem;
  }
}

.apartments_info {
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .apartments_info {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;
    margin-left: -4.8rem;
  }
}

.apartments_info__col {
  position: relative;
}
@media screen and (min-width: 1200px) {
  .apartments_info__col:first-child {
    width: 36.7%;
    padding: 11rem 0 0 4rem;
  }
}
@media screen and (max-width: 1199px) {
  .apartments_info__col:first-child {
    padding-bottom: 8.6rem;
    z-index: 2;
  }
}
@media screen and (min-width: 1200px) {
  .apartments_info__col:last-child {
    width: 60.7%;
    padding-right: 5.4rem;
  }
}
@media screen and (max-width: 1199px) {
  .apartments_info__col:last-child {
    width: 100vw;
    margin-left: -3rem;
    z-index: 2;
  }
}

.apartments_images {
  margin-left: -3rem;
}

.apartments_images__slider {
  width: 100%;
}

.apartments_images__slider_item {
  width: 100%;
}

.apartments_img {
  position: relative;
  display: block;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .apartments_img {
    padding-top: 58.6956521739%;
  }
}
@media screen and (max-width: 767px) {
  .apartments_img {
    padding-top: 80%;
  }
}

.apartments_img__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(2);
  transform-origin: 50% 80%;
}

.apartments_info__head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: absolute;
}
@media screen and (min-width: 1200px) {
  .apartments_info__head {
    top: 0;
  }
}
@media screen and (max-width: 1199px) {
  .apartments_info__head {
    top: -7.5rem;
  }
}

.apartments_info_slider {
  height: 100%;
}

@media screen and (min-width: 1200px) {
  .apartments_info_slider__item {
    padding-bottom: 21rem;
  }
  .apartments_info_slider__item.slick-active + .apartments_info_slider__item {
    margin-top: -10rem;
  }
}

.slider_count {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  margin-right: 1rem;
  font-weight: 200;
  letter-spacing: 0.61px;
  color: #1e1e0a;
}
@media screen and (min-width: 768px) {
  .slider_count {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .slider_count {
    font-size: 1.2rem;
  }
}
.slider_count span {
  font-weight: 500;
  letter-spacing: 1.04;
  color: #1e1e0a;
}
@media screen and (min-width: 768px) {
  .slider_count span {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .slider_count span {
    font-size: 1.6rem;
  }
}

.slider_count_title {
  font-weight: 400;
  color: #1e1e0a;
}
@media screen and (min-width: 1024px) {
  .slider_count_title {
    font-size: 2.4rem;
    letter-spacing: 1.04px;
  }
}
@media screen and (max-width: 1023px) {
  .slider_count_title {
    font-size: 1.6rem;
    letter-spacing: 0.69px;
  }
}

@media screen and (min-width: 1024px) {
  .apartments_info__title {
    margin-bottom: 5.4rem;
  }
}
@media screen and (max-width: 1023px) {
  .apartments_info__title {
    margin-bottom: 2rem;
  }
}

.apartments_info__descr {
  font-weight: 300;
}
@media screen and (min-width: 1024px) {
  .apartments_info__descr p {
    padding-bottom: 1.8rem;
  }
}
@media screen and (max-width: 1023px) {
  .apartments_info__descr p {
    padding-bottom: 1.6rem;
  }
}
.apartments_info__descr p:last-child {
  padding-bottom: 0;
}
.apartments_info__descr p b {
  font-weight: 400;
  letter-spacing: normal;
}
@media screen and (min-width: 1200px) {
  .apartments_info__descr {
    max-width: 45rem;
    margin-bottom: 4.6rem;
  }
}
@media screen and (min-width: 768px) {
  .apartments_info__descr {
    font-size: 1.8rem;
    letter-spacing: 0.78px;
    line-height: 1.72;
  }
}
@media screen and (max-width: 767px) {
  .apartments_info__descr {
    font-size: 1.5rem;
    letter-spacing: 0.65px;
    line-height: 1.6;
  }
}

.apartments_slider__arrow_prev,
.apartments_slider__arrow_next {
  position: absolute;
  z-index: 100;
  background: url("../images/arrow.svg") no-repeat left center;
  cursor: pointer;
  transition: transform ease-in-out 0.2s;
}
@media screen and (min-width: 1200px) {
  .apartments_slider__arrow_prev,
.apartments_slider__arrow_next {
    width: 5.6rem;
    height: 5rem;
    background-size: 25.4rem 5rem;
  }
}
@media screen and (max-width: 1199px) {
  .apartments_slider__arrow_prev,
.apartments_slider__arrow_next {
    width: 5.2rem;
    height: 2.6rem;
    background-size: 17rem 2.6rem;
  }
}

@media screen and (min-width: 1200px) {
  .apartments_slider__arrow_next {
    right: 83%;
    bottom: 5.4rem;
    transform: rotate(-90deg);
  }
  .apartments_slider__arrow_next:hover {
    transform: translateY(-3px) rotate(-90deg);
  }
}
@media screen and (max-width: 1199px) {
  .apartments_slider__arrow_next {
    bottom: -5.8rem;
    right: -2rem;
    transform: rotate(180deg);
  }
  .apartments_slider__arrow_next:hover {
    transform: translateX(-3px) rotate(180deg);
  }
}

@media screen and (min-width: 1200px) {
  .apartments_slider__arrow_prev {
    right: 83%;
    bottom: 10.6rem;
    transform: rotate(90deg);
  }
  .apartments_slider__arrow_prev:hover {
    transform: translateY(3px) rotate(90deg);
  }
}
@media screen and (max-width: 1199px) {
  .apartments_slider__arrow_prev {
    bottom: -5.8rem;
    right: 3rem;
  }
  .apartments_slider__arrow_prev:hover {
    transform: translateX(3px);
  }
}

.card_center {
  padding-left: var(--gl-indent);
  padding-right: var(--gl-indent);
}
@media screen and (min-width: 768px) {
  .card_center {
    padding-top: 13.5rem;
    padding-bottom: 19rem;
  }
}
@media screen and (max-width: 767px) {
  .card_center {
    padding-top: 3.5rem;
    padding-bottom: 20rem;
  }
}

.card_center__in {
  position: relative;
}

.card_center__decor {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.card_center__y_1,
.card_center__y_2 {
  position: absolute;
  background-color: rgba(161, 130, 2, 0.1);
}
@media screen and (min-width: 768px) {
  .card_center__y_1,
.card_center__y_2 {
    width: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .card_center__y_1,
.card_center__y_2 {
    width: 0.6rem;
  }
}

@media screen and (min-width: 768px) {
  .card_center__y_1 {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .card_center__y_1 {
    height: 22.6rem;
    bottom: -34rem;
    right: 10%;
  }
}

@media screen and (min-width: 768px) {
  .card_center__y_2 {
    bottom: -25.2rem;
    right: 11.4%;
    height: calc(100% + 38.5rem);
  }
}
@media screen and (max-width: 767px) {
  .card_center__y_2 {
    top: -6%;
    left: 9%;
    height: 50%;
    width: 0.6rem;
  }
}

.card_center__x {
  position: absolute;
  background-color: #a18202;
  opacity: 0.1;
}
@media screen and (min-width: 768px) {
  .card_center__x {
    right: -51%;
    top: 23%;
    height: 1rem;
    width: 40vw;
  }
}
@media screen and (max-width: 767px) {
  .card_center__x {
    left: -53%;
    bottom: -10.4%;
    height: 0.6rem;
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .card_center__card {
    padding: 0 26rem;
  }
}
@media screen and (max-width: 1199px) {
  .card_center__card {
    padding: 0;
  }
}

.card_center__img {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .card_center__img {
    max-width: 99.2rem;
    max-height: 49.2rem;
    margin-bottom: 3.6rem;
  }
}
@media screen and (max-width: 767px) {
  .card_center__img {
    margin-bottom: 3.2rem;
    height: max(46.2rem, 80vw);
  }
}

.card_center__img_in {
  transform: scale(1.9);
  transform-origin: 0% 15%;
}
@media screen and (max-width: 767px) {
  .card_center__img_in {
    width: 130%;
    height: 105%;
    transform-origin: 0% 2%;
  }
}

@media screen and (min-width: 1200px) {
  .card_center__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 0 5.5rem 0 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .card_center__content {
    padding-left: 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .card_center__content_text {
    width: 68%;
  }
}

.card_center__content_link {
  margin-left: auto;
}

@media screen and (min-width: 768px) {
  .card_center__title {
    margin-bottom: 3.4rem;
  }
}
@media screen and (max-width: 767px) {
  .card_center__title {
    margin-bottom: 1rem;
  }
}

.card_center__descr {
  max-width: 98rem;
}
@media screen and (min-width: 768px) {
  .card_center__descr {
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .card_center__descr {
    margin-bottom: 1rem;
  }
}

.card_center__link {
  height: 5.3rem;
  min-width: 17.4rem;
}

.icon {
  display: block;
  fill: currentColor;
}
.icon.icon--size_mod {
  width: 100%;
  height: 100%;
}